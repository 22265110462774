
import { createApp , watchEffect } from "vue";
window.createApp = createApp;

// import moment from "moment/min/moment-with-locales.js";
// import momentTimezone from "moment-timezone/builds/moment-timezone-with-data.js";
// // import io from "socket.io-client/dist/socket.io.slim.js";
// import Sortable from "frappe/public/js/lib/Sortable.min.js";

// import Vue from 'vue/dist/vue.js';
// import VueRouter from 'vue-router/dist/vue-router.js';


// Vue.prototype.__ = window.__;
// Vue.prototype.frappe = window.frappe;


// window.moment = moment;
// window.moment = momentTimezone;
// window.Vue = Vue;
// window.Sortable = Sortable;
// window.io = io;


window.observe = (wrapper, fun, disconnect=true) => {
    if (wrapper) {
        MutationObserver = window.MutationObserver || window.WebKitMutationObserver;
        var observer = new MutationObserver(function (mutations, observer) {
            fun(mutations, observer)
            if(disconnect){
                observer.disconnect()
            }
        });

        // define what element should be observed by the observer
        // and what types of mutations trigger the callback
        observer.observe(wrapper, {
            subtree: true,
            attributes: true,
            childList: true
        });
    }
} 

String.prototype.format = function () {
    // store arguments in an array
    var args = arguments;
    // use replace to iterate over the string
    // select the match and check if related argument is present
    // if yes, replace the match with the argument
    return this.replace(/{([0-9]+)}/g, function (match, index) {
      // check if the argument is present
      return typeof args[index] == 'undefined' ? match : args[index];
    });
  }; 
  

  